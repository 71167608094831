import { h } from "preact";
import { useCallback, useLayoutEffect, useRef } from "preact/hooks";

export function IframeContainer({
  children,
}: {
  children: h.JSX.Element | h.JSX.Element[];
}) {
  const ref = useRef<HTMLDivElement>(null);
  const heightRef = useRef<number>(0);
  const widthRef = useRef<number>(0);

  const handleResize = useCallback(() => {
    try {
      const div = ref.current;

      const height = Math.round(div?.getBoundingClientRect().height || 1000);
      const width = Math.round(div?.getBoundingClientRect().width || 1000);

      if (
        window.top &&
        (width !== widthRef.current || height !== heightRef.current)
      ) {
        heightRef.current = height;
        widthRef.current = width;

        const data = {
          isAsewResizeMessage: true,
          src: window.location.href.split(/[?#]/)[0],
          height,
          width,
        };

        window.top.postMessage(data, "*");
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useLayoutEffect(() => {
    if (!ref || !ref?.current) {
      return;
    }

    handleResize();

    if (typeof ResizeObserver === "function") {
      const resizeObserver = new ResizeObserver(() => {
        handleResize();
      });

      resizeObserver.observe(ref.current);

      return () => {
        resizeObserver.disconnect();
      };
    } else {
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [ref?.current]);

  return <div id="iframe-container" ref={ref} children={children} />;
}
