import { ActiveElement, Chart, ChartEvent } from "chart.js/auto";
import { h } from "preact";
import { StateUpdater, useMemo, useState } from "preact/hooks";
import { DataProvider, useData } from "../../api";
import { CSSVars } from "../../components/CSSVars";
import { ChartJs } from "../../components/ChartJs";
import { Dialog } from "../../components/Dialog";
import { IframeContainer } from "../../components/IframeContainer";
import { TenantContext, numberFormat } from "../../const";

import style from "./klimakurve-iframe.css";

function round(value: number | undefined) {
  if (value === undefined) {
    return undefined;
  }

  return Math.round(value * 100) / 100;
}

type State = null | {
  type: "handprint" | "footprint-current" | "footprint-target";
  year: number;
};

interface Props {
  tenantId: string;
}

export default ({ tenantId }: Props) => {
  return (
    <TenantContext.Provider value={tenantId}>
      <DataProvider>
        <IframeContainer>
          <Content />
        </IframeContainer>
        <CSSVars />
      </DataProvider>
    </TenantContext.Provider>
  );
};

function Content() {
  const [state, setState] = useState<State>(null);

  if (!state) {
    return (
      <div class={style.container}>
        <ChartComponent setState={setState} />
      </div>
    );
  }

  if (state?.type === "handprint") {
    return (
      <Dialog
        visibile={true}
        onClose={() => {
          setState(null);
        }}
      >
        <HandprintDialog year={state.year} />
      </Dialog>
    );
  }

  if (state?.type === "footprint-current") {
    return (
      <Dialog
        visibile={true}
        onClose={() => {
          setState(null);
        }}
      >
        <CurrentFootprintDialog year={state.year} />
      </Dialog>
    );
  }

  if (state?.type === "footprint-target") {
    return (
      <Dialog
        visibile={true}
        onClose={() => {
          setState(null);
        }}
      >
        <TargetFootprintDialog year={state.year} />
      </Dialog>
    );
  }

  console.error("Invalid State");

  return null;
}

const fontSize = 20;
const pointSize = 10;
const borderWidth = 5;
const gridLineWidth = 3;

function ChartComponent({ setState }: { setState: StateUpdater<State> }) {
  let { tenantConfig, history } = useData();

  const config = useMemo(() => {
    const labels = history.map((x) => x.year.toString());
    const data = {
      labels: labels,
      datasets: [
        {
          label: "CO₂-Handabdruck",
          data: history.map((x) => round(x.value || undefined)),
          borderColor: tenantConfig.handprintColor,
          backgroundColor: tenantConfig.handprintColor,
          borderWidth: borderWidth,
          pointRadius: pointSize,
          pointHoverRadius: pointSize,
        },
        {
          label: "CO₂-Fußabdruck",
          data: history.map((x) => round(x.footprintActual || undefined)),
          borderColor: tenantConfig.footprintActualColor,
          backgroundColor: tenantConfig.footprintActualColor,
          spanGaps: true,
          borderWidth: borderWidth,
          pointRadius: pointSize,
          pointHoverRadius: pointSize,
        },
        {
          label: "CO₂-Emissionsziel",
          data: history.map((x) => round(x.footprintTarget ?? undefined)),
          borderColor: tenantConfig.footprintTargetColor,
          backgroundColor: tenantConfig.footprintTargetColor,
          spanGaps: true,
          borderWidth: borderWidth,
          pointRadius: pointSize,
          pointHoverRadius: pointSize,
          borderDash: [10, 5],
        },
      ].filter((x) => !x.data.every((y) => y === undefined)),
    };

    return {
      type: "line",
      data: data,
      options: {
        responsive: true,
        onClick: (
          event: ChartEvent,
          elements: ActiveElement[],
          chart: Chart
        ) => {
          if (elements[0]) {
            const year = history[elements[0].index]?.year;

            if (year) {
              if (elements[0].datasetIndex === 0) {
                setState({
                  type: "handprint",
                  year,
                });
              }

              if (elements[0].datasetIndex === 1) {
                setState({
                  type: "footprint-current",
                  year,
                });
              }

              if (elements[0].datasetIndex === 2) {
                setState({
                  type: "footprint-target",
                  year,
                });
              }
            }
          }
        },
        onHover: (event: any, activeElements: any) => {
          (event?.native?.target as HTMLElement).style.cursor =
            activeElements?.length > 0 ? "pointer" : "auto";
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: fontSize,
              },
            },
            grid: {
              lineWidth: gridLineWidth,
            },
          },
          y: {
            title: {
              display: false,
              text: "titleY",
              font: {
                size: fontSize,
              },
            },
            ticks: {
              font: {
                size: fontSize,
              },
              // Include a dollar sign in the ticks
              callback: (value: number) => `${numberFormat.format(value)} tCO₂`,
            },
            grid: {
              lineWidth: gridLineWidth,
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              font: {
                size: fontSize,
              },
            },
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            mode: "index",
            position: "nearest",
            titleFont: {
              size: fontSize,
            },
            bodyFont: {
              size: fontSize,
            },
            footerFont: {
              size: fontSize,
            },
            callbacks: {
              label: (context: any) => {
                const l = context.dataset.label;
                const n = numberFormat.format(context.parsed.y);
                return ` ${l}: ${n} tCO₂`;
              },
            },
          },
        },
      },
    };
  }, [history]);

  return (
    <ChartJs
      config={config}
      width={600}
      height={innerWidth < 800 ? 600 : 300}
    />
  );
}

function HandprintDialog({ year }: { year: number }) {
  let { tenantConfig, history } = useData();

  const historyAtYear = history.find((h) => h.year === year);

  if (!historyAtYear) {
    return null;
  }

  const config = useMemo(() => {
    return {
      type: "bar",
      data: {
        labels: [year.toString()],
        datasets: Object.values(historyAtYear.handprintCategories)
          .map((c) => ({
            label: c.label,
            data: [round(c.value / historyAtYear.value)],
            borderColor: c.color,
            backgroundColor: c.color,
          }))
          .filter((dataset) => !!dataset.data[0]),
      },
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
            display: false,
          },
          y: {
            stacked: true,
            display: false,
          },
        },
        plugins: {
          legend: {
            position: "bottom",
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: true,
            align: "center",
            anchor: "center",
            formatter: (value: number) => value * 100 + "%",
            color: "white",
          },
        },
      },
    };
  }, [history]);

  return (
    <div class={style.tooltipContainer}>
      <div class={style.tooltipTitle}>CO₂-Handabdruck {year}</div>

      <div class={style.tooltipInfo}>
        In dem ausgewählten Jahr {year} wurden{" "}
        {numberFormat.format(historyAtYear.value)} tCO₂ vermieden.
      </div>

      <div class={style.tooltipContent}>
        <div class={style.tooltipContentChild}>
          <div class={style.tooltipContentTitle}>
            Vermeidungen nach Kategorien
          </div>
          <div class={style.tooltipContentBody}>
            <ChartJs config={config} width={"100%"} height={300} />
          </div>
        </div>
        <div class={style.tooltipContentChild}>
          <div class={style.tooltipContentTitle}>Anmerkungen</div>
          <div class={style.tooltipContentBody}>
            <Text text={historyAtYear.handprintText} />
          </div>
        </div>
      </div>
    </div>
  );
}

function CurrentFootprintDialog({ year }: { year: number }) {
  let { tenantConfig, history } = useData();

  const historyAtYear = history.find((h) => h.year === year);

  if (!historyAtYear) {
    return null;
  }

  const config = useMemo(() => {
    return {
      type: "bar",
      data: {
        labels: [year.toString()],
        datasets: [
          {
            label: "Scope 1",
            data: [historyAtYear.scope1],
            borderColor: tenantConfig.primaryColor,
            backgroundColor: tenantConfig.primaryColor,
          },
          {
            label: "Scope 2",
            data: [historyAtYear.scope2],
            borderColor: tenantConfig.secondaryColor,
            backgroundColor: tenantConfig.secondaryColor,
          },
          {
            label: "Scope 3",
            data: [historyAtYear.scope3],
            borderColor: tenantConfig.footprintTargetColor,
            backgroundColor: tenantConfig.footprintTargetColor,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
            display: false,
          },
          y: {
            stacked: true,
            display: false,
          },
        },
        plugins: {
          legend: {
            position: "bottom",
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: true,
            align: "center",
            anchor: "center",
            formatter: (value: number) => value + "%",
            color: "white",
          },
        },
      },
    };
  }, [history]);

  return (
    <div class={style.tooltipContainer}>
      <div class={style.tooltipTitle}>CO₂-Fußabdruck {year}</div>

      <div class={style.tooltipInfo}>
        In dem ausgewählten Jahr {year} wurden{" "}
        {numberFormat.format(historyAtYear.footprintActual || 0)} tCO₂
        ausgestoßen.
      </div>

      <div class={style.tooltipContent}>
        <div class={style.tooltipContentChild}>
          <div class={style.tooltipContentTitle}>Emissionen nach Scopes</div>
          <div class={style.tooltipContentBody} style={{ minHeight: 300 }}>
            <ChartJs config={config} width={"100%"} height={300} />
          </div>
        </div>
        <div class={style.tooltipContentChild}>
          <div class={style.tooltipContentTitle}>Anmerkungen</div>
          <div class={style.tooltipContentBody}>
            <Text text={historyAtYear.footprintActualText} />
          </div>
        </div>
      </div>
    </div>
  );
}

function TargetFootprintDialog({ year }: { year: number }) {
  let { tenantConfig, history } = useData();

  const historyAtYear = history.find((h) => h.year === year);
  const historyBaseYear = history[0];
  const historyLastYear = history.at(-1);

  if (!historyAtYear || !historyBaseYear || !historyLastYear) {
    return null;
  }

  const currentValue = historyAtYear.footprintTarget || 0;
  const baseYear = historyBaseYear.year;
  const baseValue = historyBaseYear.footprintActual || 0;
  const lastYear = historyLastYear.year;
  const lastValue = historyLastYear.footprintTarget || 0;

  const currentPercent = 100 - Math.round((currentValue / baseValue) * 100);
  const totalPercent = 100 - Math.round((lastValue / baseValue) * 100);

  return (
    <div class={style.tooltipContainer}>
      <div class={style.tooltipTitle}>CO₂-Emissionsziel {year}</div>

      <div class={style.tooltipInfo}>
        In dem ausgewählten Jahr <b>{year}</b> liegt das CO₂-Emissionsziel bei{" "}
        <b>{numberFormat.format(currentValue)}</b> tCO₂. Dies entspricht einer
        Treibhausgasreduktion von <b>{currentPercent}%</b> im Vergleich zum
        Basisjahr <b>{baseYear}</b>.
      </div>

      <div class={style.tooltipContent}>
        <div class={style.tooltipContentChild}>
          <div class={style.tooltipContentTitle}>Emissionsziel (gesamt)</div>
          <div class={style.tooltipContentBody}>
            Insgesamt ist eine Treibhausgasreduktion von <b>{totalPercent}%</b>{" "}
            bis zum Zieljahr <b>{lastYear}</b> im Vergleich zum Basisjahr{" "}
            <b>{baseYear}</b> geplant.
          </div>
        </div>
        <div class={style.tooltipContentChild}>
          <div class={style.tooltipContentTitle}>Anmerkungen</div>
          <div class={style.tooltipContentBody}>
            <Text text={historyAtYear.footprintTargetText} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Text({ text }: { text: string }) {
  return (
    <div dangerouslySetInnerHTML={{ __html: text.replaceAll("\n", "<br>") }} />
  );
}
