import { h } from "preact";
import { useState } from "preact/hooks";
import { useCurrentSaving, useData } from "../api";
import { AnimatedNumber } from "../components/AnimatedNumber";
import style from "./DefaultVisualisation.css";
import { Icon } from "./Icon";
import { Tooltip } from "./Tooltip";
import { IconKlimaKachel } from "./IconKlimaKachel";

interface DefaultVisualisationProps {}

const vergleichsgrößen = [
  {
    key: "co2",
    title: "Tonnen CO₂",
    icon: "Picto_Wolke",
    description:
      "Angezeigt wird die rechnerische Menge CO₂e, die das Stadtwerk seit dem Basisjahr durch Klimaschutzmaßnahmen vermieden hat. Zur Hochrechnung der Einsparungen des aktuellen Jahrs, für das noch keine Daten vorliegt, werden vorläufig die Werte des Vorjahres angenommen.",
    unit: "tCO₂",
    factor: 1,
  },
  {
    key: "tree",
    title: "Bäume",
    icon: "Picto_Baum",
    description:
      "Angezeigt wird eine rechnerische Anzahl an Bäumen (gemeine Fichten; 24 m Höhe; 29 cm Durchmesser auf Brusthöhe). Die dadurch hypothetisch gebundene Menge CO₂e hat das Stadtwerk seit dem Basisjahr durch Klimaschutzmaßnahmen vermieden.",
    unit: "Bäume/tCO₂",
    factor: 1.24031,
  },
  {
    key: "plane",
    title: "Mallorca Flugreisen",
    icon: "Picto_Flugzeug",
    description:
      "Angezeigt wird eine rechnerische Anzahl an Flugreisen von Frankfurt am Main nach Palma de Mallorca und zurück (bezogen auf eine Person). Die dadurch hypothetisch ausstoßene Menge CO₂e hat das Stadtwerk seit dem Basisjahr durch Klimaschutzmaßnahmen vermieden.",
    unit: "Flugreisen/tCO₂",
    factor: 1.960784,
  },
  {
    key: "car",
    title: "Erdumrundungen mit PKW",
    icon: "Picto_Auto",
    description:
      "Angezeigt wird eine rechnerische Anzahl an Umrundungen des Erdäquators mit einem Beziner-PKW. Die dadurch hypothetisch ausgestoßene Menge CO₂e hat das Stadtwerk seit dem Basisjahr durch Klimaschutzmaßnahmen vermieden.",
    unit: "Erdumrundungen/tCO₂",
    factor: 0.136565,
  },
  {
    key: "snowflake",
    title: "Fußballfelder Arktiseisfläche",
    icon: "Picto_Eis",
    description:
      "Angezeigt wird eine rechnerische Anzahl an Profifußballfeldern arktischer Sommereisfläche. Diese Fläche wird hypothetisch durch in etwa so viel CO₂e zum Schmelzen gebracht, wie das Stadtwerk seit dem Basisjahr durch Klimaschutzmaßnahmen vermieden hat.",
    unit: "Fußballfelder/tCO₂",
    factor: 0.00042,
  },
  {
    key: "steak",
    title: "Tonnen Rindfleisch",
    icon: "Picto_Fleisch",
    description:
      "Angezeigt wird eine rechnerische Anzahl an produzierten Tonnen Rindfleisch. Die dadurch hypothetisch ausgestoßene Menge CO₂e hat das Stadtwerk seit dem Basisjahr durch Klimaschutzmaßnahmen vermieden.",
    unit: "tRindfleisch/tCO₂",
    factor: 0.073529,
  },
];

export const DefaultVisualisation = function ({}: DefaultVisualisationProps) {
  // vergleichsgrößen.length = 3;

  const data = useData();

  const vgMap = Object.fromEntries(vergleichsgrößen.map((vg) => [vg.key, vg]));
  const vgArray = data.tenantConfig.comparativeFigures
    .map((key) => vgMap[key])
    .filter(Boolean);

  const [tab, setTab] = useState("co2");

  const vg = vgMap[tab] || vgArray[0];

  const value = useCurrentSaving(vg.factor);

  // const date = new Date(data.startingTimestamp).toLocaleDateString("de", {
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // });

  const date = data.startingYear;

  const c = data.tenantConfig;

  return (
    <span className={style.container}>
      <div className={style.imageContainer}>
        {c.backgroundImage ? (
          <img
            className={style.imageElement}
            src={c.backgroundImage}
            alt={c.coverageAreaName}
          />
        ) : (
          <IconKlimaKachel
            path="Key_Visual_Zähler.svg"
            width="100%"
            height="auto"
          />
        )}
      </div>

      <div className={style.counterContainer}>
        <AnimatedNumber key={tab} number={value} decimals={2} unit={vg.unit} />
        <div className={style.unit}>
          {vg.title} <Tooltip text={vg.description} />
        </div>
        <div className={style.legend}>Eingespart seit {date}</div>
      </div>

      <div className={style.tabContainer}>
        {vgArray.map((vg) => (
          <button
            title={vg.title}
            className={tab === vg.key ? style.tabButtonActive : style.tabButton}
            onClick={() => setTab(vg.key)}
          >
            <Icon path={`/assets/icons/gesamtzaehler/${vg.icon}.svg`} />
          </button>
        ))}
      </div>
    </span>
  );
};
